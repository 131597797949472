ion-content::part(scroll) {
    background: #205c40;
}
.col-12 {
    padding: 10px 20px;
    width: 100%;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    flex-wrap: wrap;
}
.col-12 div {
    width: 100%;
}
.Wiki_Ausgabe {

}
#Wiki_NewEntries {
    /*display: flex;*/
    flex-wrap: wrap;
    height: auto !important;
    overflow: hidden scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
#Wiki_NewEntries::-webkit-scrollbar {
    display: none;
}
#Wiki_NewEntries .new_entry_outer {
    width: 100%;
    padding: 20px 40px;
    display: flex;
    flex-wrap: wrap;
}
#Wiki_NewEntries .new_entry_inner {
    background: #78aa00;
    color: #fff;
    border: 5px solid #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80px;
}
#Wiki_NewEntries .inner_link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
}
#Wiki_Container, #Wiki_ContainerImp, #Wiki_ContainerOpenai, #Wiki_NewEntries {
    position: relative;
    left: 0;
    right: 0;
    margin: 25% 10px 0 10px;
    max-height: 70%;
    /*height: 100%;*/
    height: auto;
    /*overflow: hidden scroll;*/
    z-index: 30;
    display: none;
}
#Wiki_NewEntries {
    margin-top: 0;
    z-index: 25;
    max-height: 60%;
}
#Wiki_Container strong, #Wiki_ContainerImp strong {
    font-size: 20px;
    line-height: 26px;
}
#Wiki_Container p, #Wiki_ContainerImp p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}
#Wiki_Container a, #Wiki_ContainerImp a {
    text-decoration: none;
}
.wiki_header {
    text-align: center;
    padding: 50px 0 10px;
    position: relative;
    z-index: 9;
    background: #fff;
}
.wiki_background {
    position: absolute;
    width: 100%;
    z-index: -1;
    top: 0;
    height: 100%;
}
.wiki_background_icon {
    position: absolute;
    bottom: 0;
    padding: 50px 0 50px 50px;
}
#tab-button-tab4 {
    display: none;
}
@media (min-width: 768px) {
    #Wiki_NewEntries .new_entry_outer {
        width: 33.3%;
    }
    #Wiki_NewEntries .new_entry_outer {
        padding: 40px;
    }
    .Wiki_Ausgabe {
        padding: 10px 20px;
        max-height: 70vh;
        /*height: 100%;*/
        overflow: hidden scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .OpenAi_Ausgabe{
        max-height: 70vh;
        /*height: 100%;*/
        overflow: hidden scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .Wiki_Ausgabe::-webkit-scrollbar {
        display: none;
    }
    #Wiki_Container, #Wiki_ContainerImp, #Wiki_ContainerOpenai, #Wiki_NewEntries {
        position: relative;
        left: 0;
        right: 0;
        margin: 20vh auto 0;
        max-width: 1500px;
        display: none;
    }
    #Wiki_NewEntries {
        margin-top: 0;
        position: absolute !important;
        top: calc(20vh + 124px);
        padding: 0 80px;
    }
    .wiki_background {
        position: fixed;
        height: calc(100vh - 56px);
        object-fit: cover;
    }
    .wiki_background_icon {
        max-width: 800px;
    }
    #tab-button-tab4 {
        display: block;
    }
    #Wiki_ContainerOpenai{
        margin: 0 auto 0;
        max-height: 100%;
    }
}
@media (max-width: 2000px) {
    .wiki_background_icon {
        max-width: 600px;
    }
}
