#headline {
    margin-left: 5px;
}
#maxLengthrange {
    margin-right: 30px;
}
#maxLengthrangelabel {
    margin-right: 30px;
    margin-top: 20px;
    display: block;
    margin-left: 10px;
}
#maxLengthnumber {
    width: 75px;
    background: #fff;
    border: 1px solid #8f8f9d;
}
#OpenaiLoader{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: none;
}
#OpenaiLoaderImg{
    position: relative;
    max-width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}
#OpenaiSearchSubmit, #OpenaiSearchSubmit_2{
    margin-top: 20px;
}
#OpenaiSearchSubmit  {
    width: 206px;
}
#OpenaiSearchSubmit_2 {
    display: none;
    float: right;
    margin-top: 60px;
    position: absolute;
    right: 0;
}
#maxLengthrange {
    appearance: none;
    background-color: #e9e9ed;
    border: 1px solid #8f8f9d;
    height: 6px;
    border-radius: 4px;
    cursor: pointer;
}
#maxLengthrange::-moz-range-progress {
    background-color: #64a70b;
}
#maxLengthrange::-ms-fill-lower {
    background-color: #64a70b;
}
#ShowHistory_outer {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 0;
}
#ShowHistory {
    float: right;
}
#ShowHistoryModalInner {
    overflow: hidden scroll;
    margin-left: 10px;
    margin-right: 10px;
}
.history_link {
    cursor: pointer;
    border-bottom: 1px solid #64a70b;
    color: #000000;
}
.textheinweis{
    margin-left: 10px;
    font-size: 0.9rem;
}
.PromptQuestion, .PromptAnswer {
    padding: 10px;
    line-height: 1.4;
}
.PromptAnswer {
    background: #f7f7f8;
}
#AIAnfrage_Outer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
#AIAnfrage {
    width: calc(100% - 230px);
    margin-right: 20px;
}
#CopyText{
    margin-top: 10px;
    font-size: 12px;
}
#CopyText button{
    padding: 5px 20px !important;
    height: 32px !important;
}
@media (max-width: 767px) {
    #maxLengthrange {
        max-width: 160px;
    }
    #ShowHistory_outer {
        position: relative;
    }
}