.title_outer {
    display: flex;
    align-items: center;
}
.wiki_input {
    width: 100%;
}
#LoginEinblenden {
    display: none;
    z-index: 20;
    position: absolute;
}
#SetLogin {
    z-index: 40;
    position: absolute;
    background: #fff;
    margin: 5%;
    width: 90%;
    padding: 10px;
    text-align: center;
}
#LoginSubmit {
    margin: 20px 10px 5px;
}
ion-button::part(native) {
    padding: 5px 25px;
    border-radius: 0;
    height: 40px;
}
/*ion-tab-bar {*/
/*    display: none;*/
/*}*/
.wiki_input::part(native) {
    background: #ffffffe6;
}
#WikiTags_outer{
    width: 100%;
    display: flex;
    background: #fff;
    /*border-bottom: 1px solid #64a70b;*/
}
.WikiTags {
    float: left;
}
.WikiTags ion-checkbox{
    margin-right: 10px;
}
/*.WikiTags::part(native) .item-inner{*/
/*    border: 0 !important;*/
/*}*/
#Wiki_Container .input-clear-icon {
    visibility: visible !important;
}
.button_default {
    padding: 30px 30px;
    height: 40px;
    color: #78aa00;
    background: #fff;
    border-radius: 18px;
    font-weight: 600;
    border: 0;
    font-family: inherit;
    width: fit-content;
    display: flex;
    align-items: center;
}
#AlleEintraege_Header {
    width: 100%;
    padding: 80px 0 20px 40px;
    font-size: 22px;
    text-align: center;
    color: #fff;
}
#AlleEintraege_outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 80px 0 20px;
}
#AlleEintraegeButton {
    font-size: 22px;
    text-transform: none;
}
#AlleEintraegeButton::part(native) {
    padding: 30px 30px !important;
    color: #78aa00;
    border-radius: 18px;
}
@media (min-width: 768px) {
    #SetLogin {
        position: relative;
        width: 90%;
        margin: 20vh auto 0;
        max-width: 900px;
    }
    #SetLoginInner {
        background: #fff;
        border-radius: 12px;
        padding: 10px 5px;
    }
    #SetLoginInner ion-item::part(native) {
        border-bottom: 2px solid #64a70b;
        background: transparent;
    }
    #SetLoginInner ion-item:nth-child(2n)::part(native) {
        border-bottom: 0;
    }
    ion-button::part(native) {
        padding: 5px 30px;
        height: 40px;
        color: #64a70b;
        background: #fff;
        border-radius: 12px;
        font-weight: 600;
    }
}